import style from "./ReferenceService.module.scss";

const NAVIGATIONBAR = "navigationBar";
const CONTENT = "content";
const ENV_MWEB = "mweb";
const ENV_INAPP = "inapp";
const CONTENT_FILE_NAME = "content";
const CONTENT_TOP_FILE_NAME = "content-top";
const CONTENT_BOTTOM_FILE_NAME = "content-bottom";
const NAVIGATIONBAR_FILE_NAME = "navigation-bar";

const MEDIA_TYPE_AD_UNIT_INTERSTITIAL = "overlay_thumbnail";
const MEDIA_TYPE_AD_UNIT_THUMBNAIL = "overlay_thumbnail";
const MEDIA_TYPE_AD_UNIT_HEADER = "header_ad";
const MEDIA_TYPE_AD_UNIT_FOOTER = "footer_ad";
const MEDIA_TYPE_AD_UNIT_IN_ARTICLE = "in_article";
const MEDIA_TYPE_AD_UNIT_BANNER = "standard_banners";

const ADEXPERIENCE_PARAM_AD_UNIT_INTERSTITIAL = "interstitial";
const ADEXPERIENCE_PARAM_AD_UNIT_THUMBNAIL = "overlay_thumbnail";
const ADEXPERIENCE_PARAM_AD_UNIT_HEADER = "header_ad";
const ADEXPERIENCE_PARAM_AD_UNIT_FOOTER = "footer_ad";
const ADEXPERIENCE_PARAM_AD_UNIT_IN_ARTICLE = "in_article";
const ADEXPERIENCE_PARAM_AD_UNIT_BANNER = "standard_banners";

const ERROR_TYPE = {
  CREATIVE_NOT_EXIST: "creativeNotExist",
  MISSING_PARAM: "missingParam",
  PARAM_WRONG: "paramWrong",
  URL_WRONG: "urlWrong",
  NO_INTERNET: "noInternet",
  INTERNAL: "internal",
  PAGE_RESIZED: "pageResized",
  AD_BLOCKER_ON: "adBlockerOn",
  WRONG_ORIENTATION: "wrongOrientation",
  UNSUPPORTED_BROWSER: "unsupportedBrowser",
} as const;

export const orientationType = {
  PORTRAIT: "portrait",
  LANDSCAPE: "landscape",
};

export const deviceType = {
  DESKTOP: "desktop",
  MOBILE: "mobile",
  TABLET: "tablet",
};

export const adUnitUrlValues = {
  HEADER: {
    key: "h",
    label: "Header",
    uniqueId: "header_ad",
  },
  INTERSTITIAL: {
    key: "i",
    label: "Interstitial",
    uniqueId: "interstitial",
  },
  THUMBNAIL: {
    key: "t",
    label: "Thumbnail",
    uniqueId: "overlay_thumbnail",
  },
  MIDDLE_PAGE_UNIT: {
    key: "m",
    label: "Middle Page Unit",
    uniqueId: "middle_page_unit",
  },
  FOOTER: {
    key: "f",
    label: "Footer",
    uniqueId: "footer_ad",
  },
  IN_ARTICLE: {
    key: "ia",
    label: "In Article",
    uniqueId: "in_article",
  },
  BANNER: {
    key: "b",
    label: "Banner",
    uniqueId: "banner",
  },
};

function computeInterstitialDimensions({ forceFullScreen, device }: { forceFullScreen?: boolean; device?: string }) {
  // @ts-ignore - Set full size dimensions when display is in mobile mode.
  if (forceFullScreen || window.innerWidth <= style["screen-md"]) {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }
  if (device === deviceType.MOBILE) {
    return { height: style["iphone-inner-height"], width: style["iphone-inner-width"] };
  }
  return { height: style["ipad-inner-height"], width: style["ipad-inner-width"] };
}

function getThumbnailDimensions(creativeRatio: number, initialSize: number) {
  // No creative ratio specified.
  if (creativeRatio === 0) {
    return { width: initialSize, height: 135 };
  }
  if (creativeRatio >= 1) {
    if (creativeRatio === 1.77 || creativeRatio === 1.78) {
      return { width: initialSize, height: Math.round(initialSize / (16 / 9)) };
    }
    if (creativeRatio === 1.33) {
      return { width: initialSize, height: Math.round(initialSize / (4 / 3)) };
    }
    return { width: initialSize, height: Math.round(initialSize / creativeRatio) };
  }
  return { width: Math.round(initialSize * creativeRatio), height: initialSize };
}

function computeThumbnailDimensions(creativeRatio: number, device: string) {
  if (device === deviceType.DESKTOP) {
    return getThumbnailDimensions(creativeRatio, 300);
  }

  if (device === deviceType.TABLET) {
    return getThumbnailDimensions(creativeRatio, 250);
  }

  return getThumbnailDimensions(creativeRatio, 180);
}

const adUnitDictionary = (creativeRatio?: number, device: string = deviceType.MOBILE, forcePreview: boolean = false) => [
  {
    key: adUnitUrlValues.INTERSTITIAL.key,
    label: adUnitUrlValues.INTERSTITIAL.label,
    dimension: computeInterstitialDimensions({ forceFullScreen: forcePreview, device }),
    value_media_type: MEDIA_TYPE_AD_UNIT_INTERSTITIAL,
    value_adexperience_param: ADEXPERIENCE_PARAM_AD_UNIT_INTERSTITIAL,
  },
  {
    key: adUnitUrlValues.THUMBNAIL.key,
    label: adUnitUrlValues.THUMBNAIL.label,
    dimension: computeThumbnailDimensions(creativeRatio || 0, device),
    value_media_type: MEDIA_TYPE_AD_UNIT_THUMBNAIL,
    value_adexperience_param: ADEXPERIENCE_PARAM_AD_UNIT_THUMBNAIL,
  },
  {
    key: adUnitUrlValues.HEADER.key,
    label: adUnitUrlValues.HEADER.label,
    dimension: { width: 375, height: 100 },
    value_media_type: MEDIA_TYPE_AD_UNIT_HEADER,
    value_adexperience_param: ADEXPERIENCE_PARAM_AD_UNIT_HEADER,
  },
  {
    key: adUnitUrlValues.MIDDLE_PAGE_UNIT.key,
    label: adUnitUrlValues.MIDDLE_PAGE_UNIT.label,
    dimension: { width: 101, height: 180 },
    value_media_type: MEDIA_TYPE_AD_UNIT_THUMBNAIL,
    value_adexperience_param: ADEXPERIENCE_PARAM_AD_UNIT_THUMBNAIL,
  },
  {
    key: adUnitUrlValues.FOOTER.key,
    label: adUnitUrlValues.FOOTER.label,
    dimension: { width: 101, height: 180 },
    value_media_type: MEDIA_TYPE_AD_UNIT_FOOTER,
    value_adexperience_param: ADEXPERIENCE_PARAM_AD_UNIT_FOOTER,
  },
  {
    key: adUnitUrlValues.IN_ARTICLE.key,
    label: adUnitUrlValues.IN_ARTICLE.label,
    dimension: { width: 101, height: 180 },
    value_media_type: MEDIA_TYPE_AD_UNIT_IN_ARTICLE,
    value_adexperience_param: ADEXPERIENCE_PARAM_AD_UNIT_IN_ARTICLE,
  },
  {
    key: adUnitUrlValues.BANNER.key,
    label: adUnitUrlValues.BANNER.label,
    dimension: { width: 0, height: 0 }, // dimension will be get at runtime
    value_media_type: MEDIA_TYPE_AD_UNIT_BANNER,
    value_adexperience_param: ADEXPERIENCE_PARAM_AD_UNIT_BANNER,
  },
];

const environmentDictionary = [
  { key: "w", value: ENV_MWEB }, // mobile web
  { key: "a", value: ENV_INAPP }, // in app
];

const creativeTypeDictionary: readonly Record<string, string>[] = [
  { key: "360", value: "360*" },
  { key: "bd", value: "Bespoke Display (HTML5)" },
  { key: "bevc", value: "Brand Exclusive Video Chooser" },
  { key: "bs", value: "Brand Spotlight" },
  { key: "gif", value: "GIF" },
  { key: "mcta", value: "Multi CTA" },
  { key: "pv", value: "Pure Video" },
  { key: "quiz", value: "Quiz" },
  { key: "ss", value: "Site Scroller" },
  { key: "s", value: "Slideshow" },
  { key: "scc", value: "Social Carousel" },
  { key: "scd", value: "Social Display" },
  { key: "sd", value: "Static Display" },
  { key: "bv", value: "Bespoke Video" },
  { key: "sv", value: "Skin Video" },
  { key: "vv", value: "Vertical Video" },
  { key: "rd", value: "Recommendation Display" },
];

class ReferenceService {
  urlParameters = {
    URL: "url",
    AU: "au",
    E: "e",
    CID: "cid",
    SID: "sid",
    A: "a",
    P: "p",
    DEVICE: "d",
    ORIENTATION: "o",
    FORCE_PREVIEW: "forcePreview",
  };

  constants = {
    ENV_MWEB,
    ENV_INAPP,
    NAVIGATIONBAR,
    CONTENT,
    CONTENT_FILE_NAME,
    NAVIGATIONBAR_FILE_NAME,
    CONTENT_TOP_FILE_NAME,
    CONTENT_BOTTOM_FILE_NAME,
    DEFAULT_DIRECTORY_NAME: "default",
  };

  errorType = ERROR_TYPE;

  adUnitUrlValues = adUnitUrlValues;

  dictionary = {
    adUnitDictionary,
    environmentDictionary,
    creativeTypeDictionary,
  };
}

export default new ReferenceService();
