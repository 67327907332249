import { ReactElement, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import i18n from "i18n/config";
import { DemoPage, ErrorPage } from "app/Pages";
import { CreativeService, DebugService, ReferenceService } from "services";
import "assets/style/app.scss";
import { CreativeDataProps, ErrorDataProps } from "types";
import DemoPageOCS from "./app/Pages/DemoPageOCS/DemoPageOCS";
import { SiteData } from "./app/Pages/DemoPageOCS/Types";
import useIsCorrectOrientation from "./hooks/useIsCorrectOrientation";
import useResizeDetection from "./hooks/useResizeDetection";
import SiteService from "./services/SiteService/SiteService";
import AdUnitsService from "./services/AdUnitsService/AdUnitsService";

const DEMO_ROUTE = "/page";

function RedirectToDemo() {
  const location = useLocation();
  return <Navigate to={`${DEMO_ROUTE}/${location.search}`} />;
}

function App(): ReactElement {
  const resizeError = useResizeDetection();
  const correctOrientation = useIsCorrectOrientation();
  const [previewParams] = useSearchParams();
  const [initialized, setInitialized] = useState(false);
  const [siteData, setSiteData] = useState<SiteData>();

  const { environmentDictionary } = ReferenceService.dictionary;

  const forcePreviewParam = previewParams.get(ReferenceService.urlParameters.FORCE_PREVIEW);
  const adUnitParam = previewParams.get(ReferenceService.urlParameters.AU) || "";
  const creativeIdParam = previewParams.get(ReferenceService.urlParameters.CID) || "";
  const siteIdParam = previewParams.get(ReferenceService.urlParameters.SID) || "";

  const advertiserNameParam = previewParams.get(ReferenceService.urlParameters.A);
  const publisherIdParam = previewParams.get(ReferenceService.urlParameters.P);
  const clickUrl = previewParams.get(ReferenceService.urlParameters.URL) || "";

  const environmentParam = previewParams.get(ReferenceService.urlParameters.E) ?? environmentDictionary[0].key;

  const [errorData, setErrorData] = useState<ErrorDataProps>();
  const [creativeData, setCreativeData] = useState<CreativeDataProps>();

  async function getCreativeParams() {
    if (!creativeIdParam) {
      return;
    }
    try {
      DebugService.info(`Calling CreativeParams API with creative's ID ${creativeIdParam}`);
      const data = await CreativeService.getCreativeData(creativeIdParam);
      const creativeSize = data.params.creative_size ? data.params.creative_size.split("x") : [];
      setCreativeData({
        creativeId: creativeIdParam,
        name: data?.name,
        brand: data?.brand?.name,
        account: data?.account?.name,
        adFormat: data?.adFormat?.name,
        mediaType: data?.adFormat?.mediaType,
        inArticleType: data?.adFormat?.inArticleType,
        adUnit: adUnitParam,
        environment: environmentParam,
        templateId: data?.templateId,
        publisherId: publisherIdParam ?? ReferenceService.constants.DEFAULT_DIRECTORY_NAME,
        dimension: creativeSize.length === 2 ? { width: creativeSize[0], height: creativeSize[1] } : undefined,
        // @ts-ignore
        advertiserName: advertiserNameParam || data?.advertiser?.name,
        creativeRatio: data?.params?.creative_ratio || 0,
        clickUrl,
      });
    } catch (_error) {
      DebugService.error(
        // @ts-ignore
        `The call to the CreativeParams API with creative's ID ${creativeIdParam} had failed with code ${_error?.response?.status} and error message: ${_error?.response?.data?.message}`
      );
      setErrorData({
        type: ReferenceService.errorType.CREATIVE_NOT_EXIST,
      });
    }
  }

  function validateParams() {
    const isEnvironmentOK = ReferenceService.dictionary.environmentDictionary.find((obj) => obj.key === environmentParam);
    const isAdUnitOK = ReferenceService.dictionary
      .adUnitDictionary()
      .map(({ key }) => key)
      .includes(adUnitParam);

    const missingParams = [];
    const wrongParams = [];

    if (!adUnitParam) {
      missingParams.push(ReferenceService.urlParameters.AU);
    }
    if (!creativeIdParam && !siteIdParam) {
      missingParams.push(ReferenceService.urlParameters.CID);
    }

    if (!isEnvironmentOK) {
      wrongParams.push({ param: ReferenceService.urlParameters.E, value: environmentParam });
    }
    if (!isAdUnitOK) {
      wrongParams.push({ param: ReferenceService.urlParameters.AU, value: adUnitParam });
    }

    if (missingParams.length > 0) {
      DebugService.error(`The URL parameters "${missingParams.join(", ")}" are missing.`);
      setErrorData({
        type: ReferenceService.errorType.MISSING_PARAM,
      });
    } else if (wrongParams.length > 0) {
      wrongParams.forEach((wrongParam) => DebugService.error(`The URL parameter "${wrongParam.param}" with value "${wrongParam.value}" is incorrect.`));
      setErrorData({
        type: ReferenceService.errorType.PARAM_WRONG,
      });
    } else {
      getCreativeParams();
    }
  }

  async function initialize() {
    validateParams();

    await AdUnitsService.initialize();
    try {
      if (creativeIdParam) {
        await SiteService.initializeCreative(creativeIdParam);
      } else if (siteIdParam) {
        setCreativeData(undefined);
        await SiteService.initializeSite(siteIdParam);
      }

      if (SiteService.data) {
        setSiteData(SiteService.data);
      }
    } catch (err: any) {
      // do nothing in case of invalid ids
      if (err?.code === 10) {
        return;
      }

      DebugService.error(err.message);
    }

    setInitialized(true);
  }

  useEffect(() => {
    i18n.changeLanguage("en-US");
    initialize();
  }, [previewParams]);

  if (!correctOrientation && !forcePreviewParam) {
    return <ErrorPage error={{ type: ReferenceService.errorType.WRONG_ORIENTATION }} />;
  }

  if (resizeError) {
    return <ErrorPage error={{ type: ReferenceService.errorType.PAGE_RESIZED }} />;
  }

  if (!window.navigator.onLine) {
    return <ErrorPage error={{ type: ReferenceService.errorType.NO_INTERNET }} />;
  }

  if (window.navigator.userAgent.includes("Firefox")) {
    return <ErrorPage error={{ type: ReferenceService.errorType.UNSUPPORTED_BROWSER }} />;
  }

  if (errorData) {
    return <ErrorPage error={errorData} />;
  }

  const DemoRoutePage = siteData ? (
    <DemoPageOCS siteData={siteData} creativeData={creativeData} forcePreview={!!forcePreviewParam} />
  ) : (
    <DemoPage creativeData={creativeData} forcePreview={!!forcePreviewParam} />
  );
  return initialized ? (
    <Routes>
      <Route path="/preview/*" element={<RedirectToDemo />} />
      <Route path={DEMO_ROUTE} element={DemoRoutePage} />
      <Route path="/*" element={<ErrorPage error={{ type: ReferenceService.errorType.URL_WRONG }} />} />
    </Routes>
  ) : (
    <></>
  );
}

export default App;
