import { ReactElement, useEffect, useState } from "react";
import { CreativeDataProps } from "types";
import { Card, Spin } from "@ogury/design-system";
import { Creative } from "app/Pages/DemoPage/components/RightPanel/components";
import ReferenceService, { deviceType, orientationType } from "services/ReferenceService/ReferenceService";
import { useSearchParams } from "react-router-dom";
import referenceStyles from "services/ReferenceService/ReferenceService.module.scss";
import styles from "./MobileContent.module.scss";

type Props = Readonly<{
  creativeData: CreativeDataProps;
  forcePreview: boolean;
}>;

function MobileContent({ forcePreview, creativeData }: Props): ReactElement {
  const [searchParams] = useSearchParams();
  const [scale, setScale] = useState(1);
  const creativeIdParam = searchParams.get(ReferenceService.urlParameters.CID);
  const device = Object.fromEntries(searchParams)[ReferenceService.urlParameters.DEVICE] || deviceType.MOBILE;

  // ignore orientation parameter in fullscreen mode
  const orientation =
    window.innerWidth <= parseInt(referenceStyles["screen-md"], 10)
      ? undefined
      : Object.fromEntries(searchParams)[ReferenceService.urlParameters.ORIENTATION] || orientationType.PORTRAIT;

  function onResize() {
    if (window.innerWidth <= parseInt(referenceStyles["screen-md"], 10)) {
      return;
    }
    setScale(window.innerHeight / 960);
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, []);

  let deviceClass = device === deviceType.MOBILE ? styles.phone : styles.tablet;
  deviceClass = orientation === orientationType.LANDSCAPE ? `${deviceClass} ${styles.landscape}` : deviceClass;
  return (
    <Card className={styles.card}>
      <div className={deviceClass} style={{ transform: `scale(${scale})` }}>
        <div className={device === deviceType.MOBILE ? styles.phoneContent : styles.tabletContent}>
          {creativeData?.creativeId !== creativeIdParam ? (
            <Spin className={styles.loading} />
          ) : (
            <Creative key={`${device}${orientation}`} creativeData={creativeData} device={device} orientation={orientation} forcePreview={forcePreview} />
          )}
        </div>
      </div>
    </Card>
  );
}

export default MobileContent;
