import axios from "axios";
import { NETWORK_TIMEOUT } from "utils/contants";
import { URLService } from "services";
import { adUnitUrlValues } from "../ReferenceService/ReferenceService";

export type AdUnit = {
  group: string;
  name: string;
  urlKey: string;
  technicalId: string;
};
class AdUnitsService {
  adUnits: Array<AdUnit> = [];

  async initialize() {
    if (this.adUnits.length === 0) {
      const response = await axios.get(URLService.getAdUnitsURL(), { timeout: NETWORK_TIMEOUT });
      this.adUnits = response?.data?.content.map((adUnit: AdUnit) => {
        const adUnitUrlKey = Object.values(adUnitUrlValues).find((unitUrl) => adUnit.technicalId.startsWith(unitUrl.uniqueId))?.key;
        if (adUnitUrlKey) {
          return { ...adUnit, urlKey: adUnitUrlKey };
        }

        return adUnit;
      });
    }
    return null;
  }

  computeInArticleType(adUnitTechnicalId: string) {
    if (adUnitTechnicalId === "in_article-landscape") {
      return "landscape";
    }
    if (adUnitTechnicalId === "in_article-reveal") {
      return "reveal";
    }
    if (adUnitTechnicalId === "in_article-300x250") {
      return "300x250";
    }
    return "";
  }

  getAdUnitsAvailableForVideoFormat() {
    return ["in_article-landscape", "in_article-reveal", "header_ad", "footer_ad", "interstitial", "overlay_thumbnail"];
  }
}
export default new AdUnitsService();
